import React from 'react'
const Location = () => {
    return (
        <section className='info-container' >
            <div className='map-wrap'>
                <iframe title='maps' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3023.816233964855!2d-73.8528061848191!3d40.72206174493724!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c25fb4199d6563%3A0x7481321ff69a3b8!2sStacked%20Sandwich%20Shop!5e0!3m2!1sen!2sus!4v1615884098102!5m2!1sen!2sus" width='550' height='350' frameBorder='0' style={{border:1}} allowFullScreen='' aria-hidden="false" tabIndex="0"></iframe>            
            </div>
            <div className='info-wrap'>
                <h1>Questions?</h1>
                <p>Information: 845-558-3535 </p>
                <p>Email: Coming Soon</p>
                <hr></hr>
                <h1>Come On In</h1>
                <p>68-60 Austin Street, Forest Hills, NY 11375</p>
                <p>Monday-Saturday: 8am - 8pm | Sunday 8am - 6pm</p>
            </div>
        </section>
    )
}

export default Location
