import styled from 'styled-components';
    
// border: 1px solid black;
export const MainContainer = styled.div`

    height: 100%;
    width: 100%
`;


// border: 1px solid green;
export const MainContent = styled.div`

    height: 100%;
    padding: 0rem calc((100vw - 1300px) / 2);


`;


// border: 1px solid red;
export const MainItems = styled.div`

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: auto;
    padding: 0 2rem;
    color: white;
    font-family: 'Sedgwick Ave Display';
    text-transform: uppercase;


    @media only screen and (min-width: 812px) and (orientation: landscape) {
        height: inherit;
    }

    @media only screen and (min-width: 568px) and (orientation: landscape){
        height: inherit;
    }


    @media only screen and (min-width: 1024px) and (orientation: portrait) {
        height: 80vh;

    }

    @media only screen and (min-width: 1366px) and (orientation: landscape) {
        height: 100vh;
    }

`;

// border: 1px solid blue;
export const MainH1 = styled.h1`
    display: flex;
    flex-direction: center;
    justify-content: center;
    text-align: center;
    font-size: clamp(4rem, 10vw, 7rem);
    margin-bottom: 4rem;
    margin-top: 1rem;

    
`;

export const MainHr = styled.hr`
    width: 65%;
    padding-top: 2rem;

`
// border: 1px solid blue;
export const MainP = styled.a`
    display: flex;
    flex-direction: center;
    justify-content: center;
    text-align: center;
    font-size: clamp(0.8rem,2vw,1.2rem);
    margin-top: 2rem;
    padding-bottom: 1.5rem;
    color: white;
    &:hover {
        transition: 0.2s ease-out;
        cursor: pointer;
        color: white;
    
`;

// border: 1px solid blue;
// export const SubP = styled.p`

//     font-size: clamp(1.75rem, 2.5vw, 3rem);
//     margin-bottom: 2rem;
    
    
// `;

export const MainBtn = styled.a`
    font-size: clamp(1rem,2vw,1.5rem);
    padding: 0.25rem 0.75rem;
    border: none;
    border-radius: 5px;
    background: #e31837;
    transition: 0.2s ease-out;
    color: #fff;
    margin-bottom: 1rem;

    &:hover {
        background: #ffc500;
        transition: 0.2s ease-out;
        cursor: pointer;
        color: #000;
    }

    // background: white;
    // border-radius: 50px;
    // background: linear-gradient(90deg, #fcff9e 0%, #c67700 100%);
    // animation: gradient 2.5s infinite 0.8s cubic-bezier(0.2, 0.8, 0.2, 1.2) forwards;

    // @keyframes gradient {
    //     0% { background: linear-gradient(90deg, #00d2ff 0%, #3a47d5 100%) }
    //     10% { background: linear-gradient(90deg, #d53369 0%, #daae51 100%) }
    //     20% { background: linear-gradient(90deg, #FDBB2D 0%, #3A1C71 100%) }
    //     30% { background: linear-gradient(90deg, #FDBB2D 0%, #22C1C3 100%) }
    //     40% { background: linear-gradient(90deg, #f8ff00 0%, #3ad59f 100%) }
    //     50% { background: linear-gradient(90deg, #00C9FF 0%, #92FE9D 100%) }
    //     60% { background: linear-gradient(90deg, #0700b8 0%, #00ff88 100%) }
    //     70% { background: linear-gradient(90deg, #3F2B96 0%, #A8C0FF 100%) }
    //     80% { background: linear-gradient(90deg, #FC466B 0%, #3F5EFB 100%) }
    //     90% { background: linear-gradient(90deg, #4b6cb7 0%, #182848 100%) }
    //     100% { background: linear-gradient(90deg, #00d2ff 0%, #3a47d5 100%) }
    // }
`;

// border: 1px solid blue;
export const SocialIcons = styled.div`

    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 240px;
    margin-top: 2rem;


`;


// border: 1px solid blue;
export const SocialIconLink = styled.a`

    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 40px;
    &:hover {
        transition: 0.2s ease-out;
        cursor: pointer;
        color: white;
    
`;