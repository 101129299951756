import React from 'react'
import {MainContainer, MainContent, MainItems, MainH1, MainHr, MainP, MainBtn, SocialIcons, SocialIconLink} from './MainElements'
import {FaFacebook, FaInstagram} from 'react-icons/fa';
import {useState} from 'react'
import NavBar from '../Navbar';
import Sidebar from '../Sidebar';


const Main = () => {
    const [isOpen, setIsOpen] = useState(false)
 
    const toggle = () => {
        setIsOpen(!isOpen)
    }

    
    return (
        <>
            <div className='background-img'>
                <NavBar toggle={toggle}/>
                <Sidebar isOpen={isOpen} toggle={toggle}/>
                <MainContainer>
                    <MainContent>
                        <MainItems>
                            <MainH1 className='main-h1'>STACKED SANDWICH SHOP</MainH1>
                            <MainHr/>
                            <MainBtn 
                                href='https://order.toasttab.com/online/stacked-sandwich-shop'
                                target='_blank'
                                aria-label='Redirect to Toast'
                                rel='noopener noreferrer'>
                                    Order For Pick Up
                            </MainBtn>
                            <MainBtn 
                                href='https://www.grubhub.com/restaurant/stacked-sandwich-shop-68-60-austin-st-queens/5936968?fbclid=PAAaZtnbc07OKidELsLSIEYGR1kDtgDqbBzpr3LxIjRNVfM0IdATXn0OFECx8'
                                target='_blank'
                                aria-label='Redirect to Grubhub'
                                rel='noopener noreferrer'>
                                    Order For Delivery
                            </MainBtn>
                            <SocialIcons>
                                <SocialIconLink 
                                    href='https://www.facebook.com/Stacked-105998624816226'
                                    target='_blank'
                                    aria-label='Redirect to Facebook'
                                    rel='noopener noreferrer'>
                                    <FaFacebook />
                                </SocialIconLink>
                                <SocialIconLink 
                                    href='https://www.instagram.com/stackedfh'
                                    target='_blank'
                                    aria-label='Redirect to Instagram'
                                    rel='noopener noreferrer'>
                                    <FaInstagram />
                                </SocialIconLink> 
                            </SocialIcons>
                            <MainP
                                href='https://www.toasttab.com/stacked-sandwich-shop/rewardsSignup'
                                target='_blank'
                                aria-label='Redirect to Toast Rewards'
                                rel='noopener noreferrer'>
                            Join Stacked Sandwich Shop Rewards and start earning delicious rewards!
                            </MainP>
                        </MainItems>
                    </MainContent>
                </MainContainer>
            </div>
        </>
    )
} 

export default React.memo(Main)
