import styled from 'styled-components';
import HeadPic from '../../images/menu-head-wall.jpg'
import InfoPic from '../../images/black-wall-1.jpeg'


export const AboutContainer = styled.div`
    background: linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.1)),
    url(${HeadPic});
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    height: 75vh;
    max-height: 100%;
    border-top: 1px solid white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    color: white;
    padding: 0 1rem;

    @media only screen and (min-width: 320px) and (max-width: 820px) {
        background-attachment: scroll;
        background-position-x: 50%;
        background-position-y: 0%;
        background-position: center top;
        background-repeat: no-repeat;
        background-size: 110% 100%;
    }
    
    
`;

export const HeadH1 = styled.h1`
    font-size: clamp(3.5rem, 10vw, 4.5rem);
    font-family: 'Sedgwick Ave Display';
    text-transform: uppercase;
`;

export const MainP = styled.p`
    font-family: 'Sedgwick Ave Display';
    display: flex;
    flex-direction: center;
    justify-content: center;
    text-align: center;
    font-size: clamp(1rem, 2.5vw, 2rem);
    
`;

export const MoreContainer = styled.div`
    background: linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.1)),
    url(${InfoPic});
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    height: auto;
    max-height: 100%;

    border-top: 1px solid white;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    color: white;
    font-family: 'Montserrat';
    // padding: 0 2.5rem;
    
    @media only screen and (min-width: 375px) and (max-width: 767px){
        background-attachment: scroll;
        background-size: 100% 100%;;
    }
    


`;

export const InfoH1 = styled.h1`
    font-size: clamp(2.5rem, 4vw, 3rem);
    margin-top: 2.5rem;
    margin-bottom: 2rem;
    
`;

export const InfoP = styled.p`
    margin-bottom: 1rem;
    text-align: center;
    font-size: clamp(1rem, 2vw, 1.1rem);
    padding: 0.5rem 1.75rem;
`;


export const AboutButton = styled.button`
    font-size: clamp(0.75rem,2vw,1rem);
    font-weight: bolder;
    margin-bottom: 2rem;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 5px;
    background: #e31837;
    color: white;
    transition: 0.2s ease-out;
    &:hover {
        background: #ffc500;
        transition: 0.2s ease-out;
        cursor: pointer;
        color: black;
    }
`;