import styled from 'styled-components'
import menuBackground from '../../images/stacked-background-s.jpg'
// import InfoPic from '../../images/black-wall-1.jpeg'


export const TestMenuContainer = styled.a`
background: linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.1)),
url(${menuBackground});
background-attachment: fixed;
background-position: center;
background-repeat: no-repeat;
background-size: cover;


    height: 75vh;
    max-height: 100%;
    border-top: 1px solid white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    color: white;
    padding: 0 1rem;

    @media only screen and (min-width: 320px) and (max-width: 820px) {
        background-attachment: scroll;
        background-position-x: 50%;
        background-position-y: 0%;
        background-position: center top;
        background-repeat: no-repeat;
        background-size: cover;
    }
    
    
`;

export const TestA = styled.a`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: clamp(2rem, 5vw, 3rem);
    text-decoration: none;
    list-style: none;
    transition: 0.2s ease-in-out;
    font-family: 'Sedgwick Ave Display';
    color: white;
    cursor: pointer;

    &:hover{
        color: #b2ffff;
        transition: 0.2s ease-in-out;

    };
`;