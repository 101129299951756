import React from 'react'
// import {Link} from 'react-router-dom'
import {TestMenuContainer, TestA} from './TestPdfElements'
import {useState} from 'react'
import NavBar from '../Navbar';
import Sidebar from '../Sidebar';
import Footer from '../Footer';


const About = () => {
    const [isOpen, setIsOpen] = useState(false)

    const toggle = () => {
        setIsOpen(!isOpen)
    }
    
    return (
        <>  
            <div className='about-wall'>
                <NavBar toggle={toggle}/>
                <Sidebar isOpen={isOpen} toggle={toggle}/>
            </div>
            <TestMenuContainer>
                <TestA href="/assets/docs/finalStackMenu2023.pdf" download={false}>Click To View Stacked Menu</TestA>
            </TestMenuContainer>
            <Footer/>
        </>
    
    )
} 

export default React.memo(About)