import React from 'react'
import './assets/css/App.css';
import Routes from './routes/routes'


function App() {
  
  return (
    <>
      <Routes/>
    </>
  );
}

export default React.memo(App);
