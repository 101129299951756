import React from 'react'
import { SidebarContainer, Icon, CloseIcon, SidebarMenu, SidebarLink, SideBtnWrap, SidebarRoute} from "./SidebarElements";

function Sidebar({isOpen, toggle}){
    return (
        <SidebarContainer isOpen={isOpen} onClick={toggle}>
            <Icon onClick={toggle}>
                <CloseIcon/>
            </Icon>
            <SidebarMenu>
                <SidebarLink to='/'>Home</SidebarLink>
                <SidebarLink to='/menu'>Menu</SidebarLink>
                <SidebarLink to='/about'>About</SidebarLink>
            </SidebarMenu>
            <SideBtnWrap>
                <SidebarRoute
                href='https://order.toasttab.com/online/stacked-sandwich-shop'
                target='_blank'
                aria-label='Redirect to Toast'
                rel='noopener noreferrer'
                >
                    Order Pick Up
                </SidebarRoute>
                <SidebarRoute
                href='https://www.grubhub.com/restaurant/stacked-sandwich-shop-68-60-austin-st-queens/5936968?fbclid=PAAaZtnbc07OKidELsLSIEYGR1kDtgDqbBzpr3LxIjRNVfM0IdATXn0OFECx8'
                target='_blank'
                aria-label='Redirect to Toast'
                rel='noopener noreferrer'
                >
                    Order Delivery
                </SidebarRoute>
            </SideBtnWrap>
                
        </SidebarContainer>
    )
}

export default React.memo(Sidebar)
